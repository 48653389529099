import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "my-toolbox-app pb-32" }
const _hoisted_2 = { class: "sidebar-wrapper" }
const _hoisted_3 = { class: "sidebar" }
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = { class: "title-container" }
const _hoisted_6 = {
  key: 0,
  class: "form-container"
}
const _hoisted_7 = { class: "input-container-02 radiogroup" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["name", "checked"]
const _hoisted_10 = {
  key: 0,
  width: "14",
  height: "15",
  viewBox: "0 0 14 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_11 = ["stroke"]
const _hoisted_12 = {
  key: 1,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = {
  key: 0,
  class: "input-container-02"
}
const _hoisted_16 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_17 = {
  key: 1,
  class: "input-container-02"
}
const _hoisted_18 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_19 = { class: "label" }
const _hoisted_20 = {
  key: 3,
  class: "input-container-02"
}
const _hoisted_21 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_22 = {
  class: "input-container-02 x-closable-false",
  style: `height: auto`
}
const _hoisted_23 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_24 = { class: "buttons-container" }
const _hoisted_25 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_26 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_27 = { class: "credits" }
const _hoisted_28 = {
  key: 0,
  class: "content-box result"
}
const _hoisted_29 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_30 = {
  key: 1,
  width: "17",
  height: "16",
  viewBox: "0 0 17 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_31 = { class: "title-container" }
const _hoisted_32 = { class: "result-text" }
const _hoisted_33 = {
  key: 1,
  class: "content-box"
}
const _hoisted_34 = { class: "title-container" }
const _hoisted_35 = { class: "text-skeleton-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_vue_markdown = _resolveComponent("vue-markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("okr_generator.Okr_generator")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                  "okr_generator.Your_OKR_generator_that_crafts_objectives_and_key_results_that_are_clear_and_measurable"
                )), 1)
          ]),
          (
              _ctx.valuesAndTraits &&
              _ctx.valuesAndTraits.scenarios &&
              _ctx.valuesAndTraits.scenarios.definingYourValues &&
              _ctx.valuesAndTraits.scenarios.definingYourValues.questions
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetLevelOptions, (option) => {
                      return (_openBlock(), _createElementBlock("label", {
                        key: `${option.value}`,
                        class: _normalizeClass(["flex-center color-primary", [{ active: option.checked }, _ctx.optionCls(option)]]),
                        onClick: ($event: any) => (_ctx.onClickOption(option))
                      }, [
                        _createElementVNode("input", {
                          type: "radio",
                          name: option.value,
                          checked: option.checked
                        }, null, 8, _hoisted_9),
                        (option.checked)
                          ? (_openBlock(), _createElementBlock("svg", _hoisted_10, [
                              _createElementVNode("rect", {
                                x: "2.25",
                                y: "2.75",
                                width: "9.5",
                                height: "9.5",
                                rx: "4.75",
                                stroke: _ctx.companyColor,
                                "stroke-width": "4.5"
                              }, null, 8, _hoisted_11)
                            ]))
                          : (_openBlock(), _createElementBlock("svg", _hoisted_12, _cache[8] || (_cache[8] = [
                              _createElementVNode("rect", {
                                x: "0.7",
                                y: "0.7",
                                width: "12.6",
                                height: "12.6",
                                rx: "6.3",
                                stroke: "#E6E8EC",
                                "stroke-width": "1.4"
                              }, null, -1)
                            ]))),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(option.label), 1)
                      ], 10, _hoisted_8))
                    }), 128))
                  ]),
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("toolbox.Level")), 1)
                ]),
                (_ctx.visibility.role)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.roleSelected,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.roleSelected) = $event)),
                        "x-multiple": "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Eg. Software Engineer",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
                  _ctx.errors.roleSelected ? 'error' : ''
                }`)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleOptions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"]),
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("toolbox.Role")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.visibility.level)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.levelSelected,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.levelSelected) = $event)),
                        "x-multiple": "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Select Role Level",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
                  _ctx.errors.levelSelected ? 'error' : ''
                }`)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelOptions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"]),
                      _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("toolbox.Role_Level")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.visibility.missionAndVision)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(`input-container-02 textarea ${
                _ctx.errors.missionAndVision ? 'error' : ''
              }`)
                    }, [
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.missionAndVision) = $event)),
                        placeholder: "Eg. Create groundbreaking sports innovations, make our products sustainably, build a creative and diverse global team, and make a positive impact in communities where we live and work"
                      }, null, 512), [
                        [_vModelText, _ctx.missionAndVision]
                      ]),
                      _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("toolbox.Company_Mission_n_Vision")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.visibility.typeOfOrg)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.typeOfOrgSelected,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.typeOfOrgSelected) = $event)),
                        "x-multiple": "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Eg. Technology Startup",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
                  _ctx.errors.typeOfOrgSelected ? 'error' : ''
                }`)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeOfOrgOptions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"]),
                      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("toolbox.Type_of_organization")), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.coreValuesSelected,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.coreValuesSelected) = $event)),
                    multiple: "",
                    filterable: "",
                    "multiple-limit": "8",
                    "allow-create": "",
                    "default-first-option": "",
                    "reserve-keyword": false,
                    placeholder: "Add up to 8 values",
                    class: _normalizeClass(`input-44-0000000d select no-icon ${
                  _ctx.errors.coreValuesSelected ? 'error' : ''
                }`),
                    style: `height: auto`
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues
                    .questions, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: _ctx.lang(item, 'title'),
                          value: _ctx.lang(item, 'title')
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "class"]),
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("toolbox.Core_values")), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn02 btn-primary", { 'btn02-disable': !_ctx.canUseCredits || _ctx.locked }]),
                    style: {"width":"93px"},
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.generate && _ctx.generate(...args)))
                  }, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("svg", _hoisted_25, _cache[9] || (_cache[9] = [
                          _createElementVNode("path", {
                            fill: "#aaa",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ], -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("Generate")), 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn02 btn-clear", { 'btn02-disable': _ctx.locked }]),
                    style: {"width":"61px"},
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
                  }, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("svg", _hoisted_26, _cache[10] || (_cache[10] = [
                          _createElementVNode("path", {
                            fill: "#aaa",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ], -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("Clear")), 1)
                  ], 2)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_27, _toDisplayString("1 " + _ctx.$t("myAccount.credit")), 1)
      ])
    ]),
    (_ctx.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _createElementVNode("div", {
            class: _normalizeClass(["copy-text", { 'x-btn02-disable': _ctx.isTyping }]),
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.copyText && _ctx.copyText(...args)))
          }, [
            (_ctx.loading || _ctx.isTyping)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_29, _cache[11] || (_cache[11] = [
                  _createElementVNode("path", {
                    fill: "#aaa",
                    d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  }, [
                    _createElementVNode("animateTransform", {
                      attributeName: "transform",
                      attributeType: "XML",
                      type: "rotate",
                      dur: "1s",
                      from: "0 50 50",
                      to: "360 50 50",
                      repeatCount: "indefinite"
                    })
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_30, _cache[12] || (_cache[12] = [
                  _createElementVNode("path", {
                    d: "M5.16653 4.00001V2.00001C5.16653 1.63182 5.46501 1.33334 5.8332 1.33334H13.8332C14.2014 1.33334 14.4999 1.63182 14.4999 2.00001V11.3333C14.4999 11.7015 14.2014 12 13.8332 12H11.8332V13.9994C11.8332 14.3679 11.5333 14.6667 11.162 14.6667H3.17111C2.80039 14.6667 2.5 14.3703 2.5 13.9994L2.50173 4.66726C2.5018 4.29875 2.80176 4.00001 3.17295 4.00001H5.16653ZM3.83495 5.33334L3.83346 13.3333H10.4999V5.33334H3.83495ZM6.49987 4.00001H11.8332V10.6667H13.1665V2.66668H6.49987V4.00001Z",
                    fill: "black"
                  }, null, -1)
                ]))),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("Copy_Text")), 1)
          ], 2),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1)
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_vue_markdown, {
              ref: "markdown",
              source: `${!_ctx.isTyping ? _ctx.result : _ctx.result + ' | '}`
            }, null, 8, ["source"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("okr_generator.Select_between_Org_Team_or_Individual_OKRs")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t(
              "okr_generator.Add_your_core_values_to_your_role_description_to_bring_them_to_life"
            )), 1)
          ]),
          _createElementVNode("div", _hoisted_35, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3], (value) => {
              return _createElementVNode("div", {
                key: 'text-skeleton-group-' + value,
                class: "text-skeleton-group"
              }, [
                _cache[13] || (_cache[13] = _createElementVNode("div", {
                  class: "text-skeleton header",
                  style: {"width":"40%"}
                }, null, -1)),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3], (value) => {
                  return _createElementVNode("div", {
                    key: 'text-skeleton-' + value,
                    class: "text-skeleton opa-05"
                  })
                }), 64))
              ])
            }), 64))
          ])
        ]))
  ]))
}